img {
  max-width: 500px;
}

.image-shown {
  opacity: 1;
  transition: all 250ms linear;
}

.image-hidden {
  opacity: 0;
  transition: all 250ms linear;
}