@import url("https://use.typekit.net/ogk7taa.css");

:root {
  --bd-violet-rgb: 112.520718, 44.062154, 249.437846;
  --bd-accent-rgb: 255, 228, 132;
  --bd-pink-rgb: 214, 51, 132;
}

* {
  font-family: josefin-sans, sans-serif;
  font-weight: 400;
  font-style: normal;
}

h1, h2, h3, h4, h5, .title, .header, .heading {
  text-transform: uppercase;
  letter-spacing: 4px;
}

a {
  color: #543e40;
  font-weight: bold;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
}

.centered-box {
  width: 80vw;
  max-width: 1280px;
  margin: 0 auto;
}

.pink-bg {
  background: #ffcdd2;
}

.white-bg {
  background: #fff;
  background-image: linear-gradient(180deg, rgba(255,255,255, 0.01), rgba(var(--bs-body-bg-rgb), 1) 85%), radial-gradient(ellipse at top left, #ffcdd2, transparent 50%), radial-gradient(ellipse at top right, #ffcdd2, transparent 50%), radial-gradient(ellipse at center right, #ffcdd2, transparent 35%), radial-gradient(ellipse at center left, #ffcdd2, transparent 35%);
}

.masonry img {
  max-width: 100%;
}

.masonry-wrapper {
  z-index: -5;
  position: absolute;
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
  top: 0;
  left: 0;
}

.masonry {
  column-count: 5;
  column-gap: 8px;
  position: fixed;
  top: 0;
}

.masonry .mItem {
  display: inline-block;
  margin-bottom: 8px;
  width: 100%;
}

@media (max-width: 1199px) {
  .masonry {
    column-count: 3;
  }
}

@media (max-width: 991px) {
  .masonry {
    column-count: 2;
  }
}

@media (max-width: 767px) {
  .masonry {
    column-count: 1;
  }
}

.logo {
  width: 768px;
  max-width: 80%;
}

.logo-container {
  background: linear-gradient(to top, #ffcdd2, rgba(255, 255, 255, 0));
  padding: 32px;
  border-bottom: #ffcdd2 4px solid;
}

button, .btn {
  &.brand-facebook {
    background: #4267B2;
    color: #fff;

    &:hover {
      background: lighten(#4267B2, 10%);
    }
  }

  &.brand-instagram {
    background: #405DE6;
    color: #fff;

    &:hover {
      background: lighten(#405DE6, 10%);
    }
  }
}